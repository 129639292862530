import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { WidgetElements } from '../../../../utils/bi/consts';
import { CalendarState } from '../../controller';
import { CloseDialogAction } from '../closeDialog/closeDialog';
import { mapDialogTypeToWidgetComponent } from '../../../../utils/bi/mappers';
import { AddError } from '../addError/addError';
import { getSelectedSlots } from '../../../../utils/selectedSlots/selectedSlots';
import {
  bookingsCalendarClick,
  bookingsLoginUserAccountAppointmentsRescheduleSuccess,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { navigateToMembersArea } from '@wix/bookings-booking-navigation';

export type OnDialogConfirm = () => void;

export function createOnDialogConfirmAction(
  {
    getControllerState,
    context: { biLogger, calendarApi, languageSettings, t, flowAPI },
  }: ActionFactoryParams<calendarstate, CalendarContext="">,
  closeDialog: CloseDialogAction,
  addError: AddError,
): OnDialogConfirm {
  return async () => {
    const [state, setState] = getControllerState();
    const {
      selectableSlotsAtSelectedTime,
      dialog,
      selectedBookingPreferences,
      rescheduleBookingDetails,
    } = state;

    void biLogger.report(
      bookingsCalendarClick({
        component: mapDialogTypeToWidgetComponent(dialog?.type),
        element: WidgetElements.CONFIRM_BUTTON,
      }),
    );

    if (dialog!.type === DialogType.RescheduleConfirm) {
      const selectedSlot = getSelectedSlots({
        selectableSlotsAtSelectedTime: selectableSlotsAtSelectedTime!,
        dateRegionalSettingsLocale:
          languageSettings!.dateRegionalSettingsLocale!,
        t,
        selectedBookingPreferences,
      })[0].slot!;
      const bookingId = rescheduleBookingDetails!.id!;
      setState({
        dialog: {
          type: DialogType.RescheduleConfirm,
          state: DialogState.LOADING,
        },
      });

      const rescheduleResponse = await calendarApi.rescheduleBooking({
        booking: rescheduleBookingDetails!,
        slot: selectedSlot,
        onError: addError,
      });

      if (rescheduleResponse) {
        void biLogger.report(
          bookingsLoginUserAccountAppointmentsRescheduleSuccess({
            bookingId,
          }),
        );

        await navigateToMembersArea(flowAPI.controllerConfig.wixCodeApi);
      } else {
        closeDialog();
      }
    } else if (
      dialog!.type === DialogType.JoinWaitlist ||
      dialog!.type === DialogType.PremiumViewer
    ) {
      closeDialog();
    }
  };
}
</calendarstate,>